/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as EditIcon } from "./../../assets/svgs/edit-icon.svg";
import { ReactComponent as TickIcon } from "./../../assets/svgs/tick.svg";
import { ReactComponent as CrossIcon } from "./../../assets/svgs/cross.svg";
import { updateCompany } from "../../redux/companies/actionCreator";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import RenderIf from "../RenderIf/RenderIf";

const EditableInput = ({ initialValue, id, field, maxLength = 20 }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [tempValue, setTempValue] = useState(initialValue);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setInputValue(initialValue);
    setTempValue(initialValue);
  }, [initialValue]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setInputValue(tempValue);
    if (tempValue != initialValue)
      dispatch(updateCompany(id, tempValue, field));
    setIsEditing(false);
    toast.success("Link updated successfully!");
  };

  const handleCancelClick = () => {
    setTempValue(inputValue);
    setIsEditing(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleCancelClick();
    }
  };

  useEffect(() => {
    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  const displayValue =
    inputValue?.length > maxLength
      ? inputValue.substring(0, maxLength) + "..."
      : inputValue == ""
      ? "N/A"
      : inputValue;

  return (
    <div
      ref={wrapperRef}
      className="flex items-center space-x-2 dark:bg-[#2a3142] bg-white text-[#556EE6]"
    >
      {!isEditing && (
        <RenderIf
          isTrue={displayValue != "N/A"}
          fallback={<span className="truncate">{displayValue}</span>}
        >
          <span className="truncate">
            <a
              target="_blank"
              href={`${
                inputValue?.includes("http")
                  ? inputValue
                  : "https://".concat(inputValue)
              }`}
            >
              {displayValue}
            </a>
          </span>
        </RenderIf>
      )}
      {isEditing && (
        <input
          type="text"
          value={tempValue}
          onChange={(e) => setTempValue(e.target.value)}
          className="border-none rounded px-2 py-1 dark:bg-[#2E3548] bg-[#F8F9FA] dark:text-white text-black"
        />
      )}
      {!isEditing && (
        <button onClick={handleEditClick} className="p-1">
          <EditIcon />
        </button>
      )}
      {isEditing && (
        <div className="flex space-x-1 ">
          <button onClick={handleSaveClick} className="p-1">
            <TickIcon />
          </button>
          <button onClick={handleCancelClick} className="p-1">
            <CrossIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default EditableInput;
