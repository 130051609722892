import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSubAccount,
  fetchAgencyEmail,
  fetchAgencyLogo,
} from "../../redux/companies/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import RenderIf from "../../components/RenderIf/RenderIf";

const DashboardLinkPage = () => {
  const { location_id } = useParams();
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState(false);
  const { agencyEmail, agencyLogo, currentCompany, success } = useSelector(
    (state) => state.Companies
  );
  const [content, setContent] = useState("");

  useEffect(() => {
    if (location_id) {
      dispatch(fetchSubAccount(location_id));
      dispatch(fetchAgencyEmail(location_id));
      dispatch(fetchAgencyLogo(location_id));
    }
  }, [dispatch, location_id]);

  useEffect(() => {
    if (currentCompany && success) {
      const agencyDashboardLink = currentCompany?.agency_dashboard;
      if (agencyDashboardLink) {
        if (currentCompany.payment_status === "Paid") {
          window.location.href = agencyDashboardLink.startsWith("http")
            ? agencyDashboardLink
            : `https://${agencyDashboardLink}`;
        } else {
          const message =
            currentCompany.payment_status === "Unpaid"
              ? "Please contact "
              : "Your SEO services have been paused. Please contact ";
          setContent(`${message}${agencyEmail?.email} to learn more.`);
        }
      } else {
        const message =
          currentCompany.payment_status === "Paid"
            ? "Your Dashboard is being generated and will be available shortly! Please contact "
            : currentCompany.payment_status === "Unpaid"
            ? "We are now offering SEO services! Please contact "
            : "Your SEO services have been paused. Please contact ";
        setContent(`${message}${agencyEmail?.email} to learn more.`);
      }
    }
  }, [currentCompany, agencyEmail, success]);

  return (
    <div className="bg-gray-200 w-full h-screen flex flex-col items-center justify-center">
      {content ? (
        <div className="flex flex-col items-center">
          <span>
            <RenderIf
              isTrue={agencyLogo && !imageError}
            >
              <img
                src={agencyLogo}
                alt="No Logo"
                className="h-12"
                onError={() => setImageError(true)}
              />
            </RenderIf>
          </span>
          <div className="font-semibold text-2xl mt-10 text-center">
            {content}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-screen ">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={["#556ee6", "#556ee6", "#556ee6", "#556ee6", "#556ee6"]}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardLinkPage;
