/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DeleteCompanyIcon } from "./../../assets/svgs/delete-strategy-icon.svg";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DeleteCompany from "../../components/DeleteCompany/DeleteCompany";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { changePassword } from "../../redux/companies/actionCreator";

import Button from "./../../components/Button/Button";
import { ReactComponent as ViewIcon } from "./../../assets/svgs/password.svg";
import { ReactComponent as HideIcon } from "./../../assets/svgs/hide-icon.svg";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [isDeleteIconOpen, setIsDeleteIconOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, error, loading } = useSelector(
    (state) => state.Companies
  );

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const toggleDeleteIcon = () => {
    setIsDeleteIconOpen(!isDeleteIconOpen);
  };

  useEffect(()=>{
    if(!loading && success == "Password changed successfully!"){
      toast.success(success);
      setIsDeleteIconOpen(!isDeleteIconOpen);
      navigate("/");
  }
    if(!loading && error){
      toast.error(error);
      setIsDeleteIconOpen(!isDeleteIconOpen);
    }
  },[success, error])

  const validateAndSignup = (event) => {
    event.preventDefault();
    if (passwordError || confirmPasswordError) {
      toast.error("Please resolve the errors.");
      return;
    }
    toggleDeleteIcon()
  };

  const performUpdation = ()=>{
    dispatch(changePassword(oldPassword,password))
  }

  const goToHomePage = ()=> {
    navigate("/");
  }

  useEffect(() => {
    if (
      password &&
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/)
    ) {
      setPasswordError(
        "Password must be at least 8 characters long, include a number, an uppercase, a lowercase letter and a special letter."
      );
    } 
    else if(password == oldPassword && oldPassword!=""){
        setPasswordError(
            "Old and New password must not be same"
          );    
    }
    else {
      setPasswordError("");
    }

    if (confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  }, [password, confirmPassword,oldPassword]);

  return (
    <div>
      <Header />
      <div className="flex flex-col min-h-[85vh] bg-[#f1f2f6] dark:bg-[#222737] w-full text-white">
        <div className="flex-grow">
          <div className="text-white flex flex-row justify-between">
            <div className="flex flex-row pl-[24px] pt-[26px]">
              <div className="text-base font-semibold ml-[8px] text-[#32394E] dark:text-white">
                PROFILE SETTINGS
              </div>
            </div>
          </div>

          <div className="bg-white dark:bg-[#2A3042] mx-[24px] mt-[25px] rounded-lg">
            <div className="flex flex-col p-[24px]">
              <div className="text-base font-semibold text-[#32394E] dark:text-white">
                CHANGE PASSWORD
              </div>
              <form onSubmit={validateAndSignup} className="text-black">
                <div className="flex flex-col md:flex-row justify-between md:space-x-[24px]">
                  <div className="flex flex-col w-full">
                    <div className="font-semibold text-sm mt-8 text-[#5A5F7D] dark:text-[#C3CBE4]">
                      Old Password
                    </div>
                    <div className="relative flex items-center mt-1 ">
                      <input
                        type={isOldPasswordVisible ? "text" : "password"}
                        placeholder="Enter Old password"
                        className="border-[1px] border-[#E3E6EF] h-9 pl-3 pr-9 pt-2 pb-2 text-sm rounded w-full dark:bg-[#2E3548] dark:border-[1px] dark:border-[#32394E] dark:border-solid dark:text-white"                        
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                      {isOldPasswordVisible ? (
                        <HideIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={toggleOldPasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={toggleOldPasswordVisibility}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="font-semibold text-sm mt-8 text-[#5A5F7D] dark:text-[#C3CBE4]">
                      New Password
                    </div>
                    <div className="relative flex items-center mt-1">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter new password"
                        className="border-[1px] border-[#E3E6EF] h-9 pl-3 pr-9 pt-2 pb-2 text-sm rounded w-full dark:bg-[#2E3548] dark:border-[1px] dark:border-[#32394E] dark:border-solid dark:text-white"                        
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {isPasswordVisible ? (
                        <HideIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </div>
                    {passwordError && (
                      <div className="text-red-500 text-xs mt-1">
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="font-semibold text-sm mt-8 text-[#5A5F7D] dark:text-[#C3CBE4]">
                      Confirm Password
                    </div>
                    <div className="relative flex items-center mt-1">
                      <input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        placeholder="Confirm password"
                        className="border-[1px] border-[#E3E6EF] h-9 pl-3 pr-9 pt-2 pb-2 text-sm rounded w-full dark:bg-[#2E3548] dark:border-[1px] dark:border-[#32394E] dark:border-solid dark:text-white"                        
                        required
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {isConfirmPasswordVisible ? (
                        <HideIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      )}
                    </div>
                    {confirmPasswordError && (
                      <div className="text-red-500 text-xs mt-1">
                        {confirmPasswordError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-8 flex justify-end space-x-4">
                  <div className="">
                    <Button
                      backgroundColor="#556EE6"
                      borderColour="#556EE6"
                      textColor="#F8F8F8"
                      textContent="Cancel"
                      hoverTextColor="#556EE6"
                      widthStatus={true}
                      togglePopup={goToHomePage}
                    />
                  </div>
                  <div>
                    {" "}
                    <Button
                      backgroundColor="#556EE6"
                      borderColour="#556EE6"
                      textColor="#F8F8F8"
                      textContent="Update"
                      hoverTextColor="#556EE6"
                      widthStatus={true}
                      onClick={toggleDeleteIcon}
                    />
                  </div>{" "}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Dialog
        open={isDeleteIconOpen}
        onClose={() => setIsDeleteIconOpen(false)}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: { borderRadius: "7px", background: "#2A3042" },
        }}
      >
        <DeleteCompany
          icon={<DeleteCompanyIcon />}
          title={"Are you sure you want to change your password?"}
          content={"Your password will be changed."}
          button={"Update"}
          buttonColour="var(--tooltip-price-color)"
          togglePopup={toggleDeleteIcon}
          deleteCurrentCompany={performUpdation}
        />
      </Dialog>
    </div>
  );
};

export default ChangePassword;
