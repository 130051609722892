import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { fetchLoggedInUser } from "../../redux/agencies/actionCreator";

const AuthGuard = ({ children }) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  let isAuthenticated = false;
  let user = null;

  if (token) {
    try {
      user = jwtDecode(token); // Decode token to get user details
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (user.exp > currentTime) {
        isAuthenticated = true;
        dispatch(fetchLoggedInUser(user)); // Dispatch only if token is valid
      } else {
        console.error("Token has expired");
        localStorage.removeItem("token"); // Remove expired token
      }
    } catch (error) {
      console.error("Invalid token", error);
      localStorage.removeItem("token"); // Remove invalid token
    }
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthGuard;
