import React, { useState, useEffect } from "react";
import CompanyField from "../CompanyField/CompanyField";
import { ReactComponent as DropDownIcon } from "./../../assets/svgs/drop-down.svg";
import Avatar from 'react-avatar';
import RenderIf from "../RenderIf/RenderIf";

const CompanyDetails = ({ currentCompany, id, status }) => {
  const [isShowMore, setIsShowMore] = useState(
    status === "subAccount" ? true : false
  );
  const [imageError, setImageError] = useState(false);
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return isNaN(date) ? "-" : date.toISOString().split("T")[0];
  };

  // Function to check if the image URL is valid
  const checkImageUrl = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (!response.ok) {
        setImageError(true); // If the response is not ok, it means the image doesn't exist
      }
    } catch (error) {
      setImageError(true); // Catch any other errors (e.g., network issues)
    }
  };  

  useEffect(() => {
    if (currentCompany?.logo_url) {
      checkImageUrl(currentCompany.logo_url);
    }
  }, [currentCompany?.logo_url]);

  return (
    <div>
      <div className="bg-white dark:bg-[#2A3042] mx-[24px] mt-[25px] pb-[44px] pl-[39px] rounded-lg">
        <div className="flex md:flex-row flex-col text-white text-left font-normal">
          {/* Image started */}
          <div className="flex flex-row md:flex-col text-center mt-6 justify-center md:justify-start">
            <RenderIf isTrue={!imageError && currentCompany?.logo_url}>
            <div className="rounded-full mr-1 overflow-hidden bg-white border-solid border-[1px] border-[#9dA6C4]">
              <Avatar
                size={60}
                src={currentCompany?.logo_url}
                alt="Company logo"
                className="rounded-full w-[60px] h-[60px] min-h-[60px] min-w-[60px] object-contain"
                onError={() => setImageError(true)}
              />
            </div>
            </RenderIf>
          </div>
          {/* Image ended */}
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-20 mx-[50px]">
            <CompanyField title="Name" content={currentCompany?.name || "-"} />
            <CompanyField
              title="Installed On"
              content={formatDate(currentCompany?.date_added)}
            />
            <CompanyField title="ID" content={id} />
            <CompanyField
              title="Email"
              content={currentCompany?.email || "-"}
            />
            <CompanyField
              title="Phone"
              content={currentCompany?.phone || "-"}
            />
            <CompanyField
              title="Website"
              content={currentCompany?.website || "-"}
            />
            <CompanyField
              title="Currency"
              content={currentCompany?.currency || "-"}
            />
            <CompanyField
              title="Customer Type"
              content={currentCompany?.customer_type || "-"}
            />
            <CompanyField
              title="Customer Count"
              content={currentCompany?.onboarding_info?.customerCount || "-"}
            />
            <CompanyField
              title="Business Niche"
              content={currentCompany?.business_niche || "-"}
            />
            <CompanyField
              title="Country"
              content={currentCompany?.country || "-"}
            />
            <CompanyField title="City" content={currentCompany?.city || "-"} />
            <CompanyField
              title="State"
              content={currentCompany?.state || "-"}
            />
            <CompanyField
              title="Postal Code"
              content={currentCompany?.postal_code || "-"}
            />
            <CompanyField
              title="Address"
              content={currentCompany?.address || "-"}
            />
            <CompanyField
              title="Domain"
              content={currentCompany?.domain || "-"}
            />
            <CompanyField
              title="Timezone"
              content={currentCompany?.timezone || "-"}
            />
            <RenderIf isTrue={currentCompany?.customer_type != 'agency'}>
            <CompanyField
              title="Payment"
              content={currentCompany?.payment_status || "-"}
            />
            </RenderIf>
            <RenderIf isTrue={isShowMore}>
              <CompanyField
                title="Relationship Number"
                content={currentCompany?.relationship_number || "-"}
              />
              <CompanyField
                title="Plan"
                content={currentCompany?.plan || "-"}
              />
              <CompanyField
                title="Privacy Policy"
                content={currentCompany?.privacy_policy || "-"}
              />
              <CompanyField
                title="Spare Domain"
                content={currentCompany?.spare_domain || "-"}
              />
              <CompanyField
                title="Sub Domain"
                content={currentCompany?.subdomain || "-"}
              />
              <CompanyField
                title="Reselling"
                content={currentCompany?.is_reselling ? "True" : "False"}
              />
              <CompanyField
                title="Industry Served"
                content="Consulting / Agency"
              />
              <CompanyField
                title="Agency Primary Use"
                content={currentCompany?.agency_primary_use || "-"}
              />
              <CompanyField
                title="Terms of Service Version"
                content={formatDate(currentCompany?.terms_of_service_version)}
              />
              <CompanyField
                title="Terms of Service Accepted By"
                content={currentCompany?.terms_of_service_accepted_by || "-"}
              />
              <CompanyField
                title="Terms of Service Accepted Date"
                content={formatDate(
                  currentCompany?.terms_of_service_accepted_date
                )}
              />
              <CompanyField
                title="Referral ID"
                content={currentCompany?.referral_id || "-"}
              />
              <CompanyField
                title="Stripe Connect ID"
                content={currentCompany?.stripe_connect_id || "-"}
              />
              <CompanyField
                title="Privacy Policy Version"
                content={formatDate(currentCompany?.privacy_policy_version)}
              />
              <CompanyField
                title="Privacy Policy Accepted By"
                content={currentCompany?.privacy_policy_accepted_by || "-"}
              />
              <CompanyField
                title="Privacy Policy Accepted Date"
                content={formatDate(
                  currentCompany?.privacy_policy_accepted_date
                )}
              />
              <CompanyField
                title="Upgrade Enabled For Clients"
                content={
                  currentCompany?.upgrade_enabled_for_clients ? "True" : "False"
                }
              />
              <CompanyField
                title="Stripe Connect Initiated By"
                content={
                  currentCompany?.saas_settings?.stripeConnectInitiatedBy || "-"
                }
              />
              <CompanyField
                title="Affiliate Policy Version"
                content={formatDate(currentCompany?.affiliate_policy_version)}
              />
              <CompanyField
                title="Affiliate Policy Accepted By"
                content={currentCompany?.affiliate_policy_accepted_by || "-"}
              />
              <CompanyField
                title="Affiliate Policy Accepted Date"
                content={formatDate(
                  currentCompany?.affiliate_policy_accepted_date
                )}
              />
            </RenderIf>
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-row h-[38px] mt-[-18px] mb-3">
        <div
          className={`flex bg-[#556EE6] rounded-full items-center cursor-pointer justify-center ${
            status === "subAccount" && "hidden"
          }  ${isShowMore && "bg-[#55A7EE]"}`}
          onClick={() => setIsShowMore(!isShowMore)}
        >
          <button className=" text-[#F8F8F8] font-semibold p-2 ml-2">
            <RenderIf isTrue={isShowMore} fallback={<span>Show More</span>}>
              <span>Show Less</span>
            </RenderIf>
          </button>
          <span className="mx-2 my-[9px] mr-[16px] text-base text-white ">
            <DropDownIcon />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
