import React, { useEffect } from "react";

const ChatWidget = ({}) => {
  useEffect(() => {
    const addScript = () => {
      const script = document.createElement("script");
      script.src = "https://widgets.leadconnectorhq.com/loader.js";
      script.setAttribute(
        "data-resources-url",
        "https://widgets.leadconnectorhq.com/chat-widget/loader.js"
      );
      script.setAttribute("data-widget-id", "671a59699d106ab6950feb89");
      document.body.appendChild(script);
    };
    addScript();

    return () => {
      const existingScript = document.querySelector(
        `script[src="https://widgets.leadconnectorhq.com/loader.js"]`
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null;
};

export default ChatWidget;
