import React from "react";
import DataTable from "../../components/DataTable/DataTable";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import { fetchNames } from "../../redux/names/actionCreator";
import { fetchAgencies } from "../../redux/agencies/actionCreator";
import { useNavigate } from "react-router-dom";
import { clearMessage } from "../../redux/agencies/actionCreator";
import { clearMessage as clearMessageCompanies } from "../../redux/companies/actionCreator";
import { clearMessage as clearMessageNames } from "../../redux/names/actionCreator";

function ListPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { error } = useSelector(
    (state) => state.Companies
  );

  useEffect(() => {
    dispatch(fetchNames());
    dispatch(fetchAgencies());
  }, [dispatch]);

  useEffect(()=>{
    if (error == "Request failed with status code 403"){
      localStorage.removeItem("token");
      dispatch(clearMessage());
      dispatch(clearMessageCompanies());
      dispatch(clearMessageNames());
      navigate("/login")      
    }
  },[error])

  return (
    <div className="min-h-screen flex flex-col dark:bg-[#2A3042] bg-white h-[100vh]">
      <Header />
      <div className=" dark:bg-[#222737] bg-[#f4f4f8] text-white">
        <div className="w-full">
          <DataTable />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ListPage;
