import axios from "axios";

const getToken = () => localStorage.getItem("token");
const api = {
  axiosInstance: axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    params: {
      limit: 2000,
    },
  }),
  companies: {
    getAll: async () => api.axiosInstance.get("/companies"),
    get: async (params) =>
      api.axiosInstance.get(`/companies/search?q=${params}`),
    getCompany: async (id) => api.axiosInstance.get(`/company/${id}`),
    updateCompany: async (id, value, key) =>
      api.axiosInstance.patch(`/company/${id}`, { [key]: value }),
    getSubAccount: async (id) =>
      api.axiosInstance.get(`/company/sub-account/${id}`),
    fetchAgencyLogoOfSubAccount: async (id) =>
      api.axiosInstance.get(`/company/sub-account/agency/logo/${id}`),
    fetchAgencyEmailOfSubAccount: async (id) =>
      api.axiosInstance.get(`/company/sub-account/agency/${id}`),
    getAllNames: async () => api.axiosInstance.get("/companies/names"),
    getAllAgencies: async () =>
      api.axiosInstance.get("/companies/agency-names"),
    getAllStatuses: async () => api.axiosInstance.get("/companies/statuses"),
    deleteCompany: async (id) => api.axiosInstance.delete(`/company/${id}`),
    deleteSubAccount: async (location_id) =>
      api.axiosInstance.delete(`/company/sub-account/${location_id}`),
    loginAgencyUser: async (email, password) =>
      api.axiosInstance.post(`/login`, { email: email, password: password }),
    forgetPassword: async (email) =>
      api.axiosInstance.post(`/forget-password`, { email: email }),
    changePassword: async (oldPassword, newPassword) =>
      api.axiosInstance.post(`/change-password`, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
    resetPassword: async (token, newPassword) =>
      api.axiosInstance.post(`/reset-password`, {
        token: token,
        newPassword: newPassword,
      }),
    signupAgencyUser: async (
      agencyName,
      email,
      password,
      streetAddress,
      city,
      state,
      zipCode
    ) =>
      api.axiosInstance.post(`/signup`, {
        email,
        password,
        agencyName: agencyName,
        streetAddress,
        city,
        state,
        zipCode,
      }),
  },
};

// Add an interceptor to include the token in the Authorization header
api.axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
