/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import RenderIf from "../RenderIf/RenderIf";
import { setFiltersListPage } from "../../redux/companies/actionCreator";
import { useDispatch, useSelector } from "react-redux";

const DropDownComponent = ({
  options,
  placeholder,
  filtering,
  handleFilterChange,
  value,
  onChange,
  clickedClearFilters,
  setPage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { filters } = useSelector((state) => state.Companies);
  const [selectedOption, setSelectedOption] = useState(
    filtering === "customer_type"
      ? filters?.customer_type
      : filtering === "payment_status"
      ? filters?.status
      : null
  );

  const dropdownRef = useRef(null);

  const toggling = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (handleFilterChange) {
      value === "All Statuses" ||
      value === "All Customer Types"
        ? handleFilterChange(filtering, "")
        : handleFilterChange(filtering, value);
    }
  }, []);

  const onOptionClicked = (value) => () => {
    if (setPage) {
      setPage(0);
    }
    setSelectedOption(value);
    setIsOpen(false);
    if (handleFilterChange) {
      if (
        value === "All Statuses" ||
        value === "All Customer Types" ||
        value === "All Agencies"
      ) {
        handleFilterChange(filtering, "");
        dispatch(setFiltersListPage({ [filtering]: "" }));
      } else {
        handleFilterChange(filtering, value);
        dispatch(setFiltersListPage({ [filtering]: value }));
      }
    }

    if (filtering === "pageSize") {
      onChange(value);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
    setSelectedOption(value);
  }, [clickedClearFilters]);

  return (
    <div
      className="select-none font-medium relative text-base whitespace-pre-wrap"
      ref={dropdownRef}
    >
      <div
        className="min-w-[129px] h-[36px] p-1 flex items-center justify-between cursor-pointer font-normal border-[1px] border-solid dark:border-[#32394E] dark:bg-[#2E3548] border-[#CED4DA] rounded-md px-4 py-2 text-xs dark:text-[#C3CBE4] text-black whitespace-nowrap"
        onClick={toggling}
      >
        {selectedOption || placeholder}
        <span className="mx-2 rotate-90 text-base dark:text-[#C3CBE4] text-black">
          &#x276F;
        </span>
      </div>
      <RenderIf isTrue={isOpen}>
        <ul className="p-0 w-full absolute shadow-md font-normal text-xs mt-1 list-none rounded-md border-solid dark:border-[#30384A] text-[#333B4E] dark:text-[#C3CBE4] dark:bg-[#2A3042] bg-white z-10 max-w-full max-h-[270px] overflow-auto border-[1px] border-[#CED4DA]">
          {options.map((option) => (
            <li
              className={`cursor-pointer text-start p-2 font-normal text-xs hover:text-[#556EE6] ${selectedOption == option && 'dark:text-white text-gray-400'}`}
              onClick={onOptionClicked(option)}
              key={option}
            >
              {option}
            </li>
          ))}
        </ul>
      </RenderIf>
    </div>
  );
};

export default DropDownComponent;
