import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as BackIcon } from "./../../assets/svgs/back-icon.svg";
import { toast } from "react-toastify";
import {forgotPassword} from "./../../redux/companies/actionCreator"
const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loggedInUser, loading } = useSelector(
    (state) => state.Agencies
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail();
  };

  useEffect(() => {
    if (!loading && loggedInUser && Object.keys(loggedInUser).length > 0) {
      toast.success("New user created successfully!");
      navigate("/");
    }
    if (error) {
      toast.error(error);
    }
  }, [loggedInUser, error, loading, navigate]);

  const sendEmail = () => {
    dispatch(forgotPassword(email))
    navigate("/forgot-password/email-verification"); 
    toast.success("Email sent successfully!");
  };

  return (
    <div className="flex justify-center items-center bg-[#f9f6ff] h-screen px-4 lg:px-0">
      <div className="lg:w-[50%] bg-white pt-10 px-4 md:px-8 lg:px-14 flex flex-col rounded-2xl">
        <div className="flex flex-row justify-center">
          <span>
            <img
              src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
              alt="Scepter Logo"
              className="h-16 w-44"
            />
          </span>
        </div>

        <div className="font-semibold text-2xl text-[#333B4E] mt-12">
          Forgot Password?
        </div>
        <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
          Enter your registered email address to change your Scepter account
          password.
        </div>
        <form onSubmit={handleSubmit}>
          <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
            Email
          </div>
          <input
            type="email"
            placeholder="Enter Email"
            className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="mt-8">
            <button
              type="submit"
              className="w-full text-white bg-[#556EE6] h-[36px] rounded hover:bg-[#404e9a] font-bold text-sm"
            >
              Reset Password
            </button>
          </div>
          <div className="max-w-max mx-auto">
            <Link to="/" className="cursor-pointer">
              <div className="flex flex-row mt-[24px] text-[#556EE6] justify-center items-center mb-[24px] space-x-[4px]">
                <BackIcon className="h-[12px] w-[14px] font-bold" />
                <div className="text-sm font-semibold text-[#556EE6] dark:text-white">
                  Back to Login
                </div>
              </div>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
