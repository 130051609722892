import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Ensure correct import of jwt-decode
import Button from "../Button/Button";
import { ReactComponent as ViewIcon } from "./../../assets/svgs/password.svg";
import { ReactComponent as HideIcon } from "./../../assets/svgs/hide-icon.svg";
import RenderIf from "../RenderIf/RenderIf";
import { loginAgency } from "../../redux/agencies/actionCreator";
import { toast } from "react-toastify";
import ChatWidget from "../ChatWidget/ChatWidget";

const LoginForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Hook from react-router-dom for navigation

  const { error, loggedInUser, loading } = useSelector(
    (state) => state.Agencies
  );

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    dispatch(loginAgency({ email, password }));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        jwtDecode(token);
        navigate("/");
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (!loading && loggedInUser && Object.keys(loggedInUser).length > 0) {
      toast.success("User Logged In Successfully!");
      navigate("/");
    }
    if (error) toast.error(error);
  }, [loggedInUser, error, loading, navigate]);

  return (
    <div className="w-full md:w-1/2 bg-white pt-[43px] px-[35px] md:px-[70px] lg:px-[139px] flex flex-col mb-[32px]">
      <div className="font-semibold text-2xl text-[#333B4E]">
        Sign In to Scepter
      </div>
      <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
        Please enter credentials to sign in.
      </div>
      <form onSubmit={handleSignIn}>
        <div className="font-normal text-sm mt-[16px] text-[#5A5F7D]">
          Email
        </div>
        <input
          type="email"
          required
          placeholder="Enter Email"
          className="border-[1px] border-[#E3E6EF] h-[36px] px-[12px] pt-[8px] pb-[7px] text-sm rounded mt-[4px] w-full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="font-normal text-sm mt-[24px] text-[#5A5F7D]">
          Password
        </div>
        <div className="relative flex items-center mt-[4px]">
          <input
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Enter password"
            className="border-[1px] border-[#E3E6EF] h-[36px] pl-[12px] pr-[36px] pt-[8px] pb-[7px] text-sm rounded w-full"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <RenderIf
            isTrue={isPasswordVisible}
            fallback={
              <ViewIcon
                className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            }
          >
            <HideIcon
              className="absolute right-[12px] h-5 w-5 text-gray-400 cursor-pointer"
              onClick={togglePasswordVisibility}
            />
          </RenderIf>
        </div>

        <div
          className="font-semibold text-sm text-[#556EE6] mt-[12px] text-right mb-[32px] cursor-pointer"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password?
        </div>

        <Button
          backgroundColor="#556EE6"
          borderColour="#556EE6"
          textColor="#F8F8F8"
          widthStatus={true}
          hoverTextColor="#556EE6"
          textContent="Sign In"
        />

        <div className="font-normal text-sm mt-[24px] text-[#5A5F7D] text-center">
          Don't have an account?{" "}
          <Link to="/signup">
            <span className="font-semibold text-sm text-[#556EE6]">
              Sign Up
            </span>
          </Link>
        </div>
        <ChatWidget/>
        <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
        <span className="font-semibold mt-[24px]">Note:{" "}</span>
          If you are signing in for the first time please check your agency email
          address for the credentials.
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
