import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ReactComponent as CloseButtonLightMode } from "./../../assets/svgs/close-button-light-mode.svg";

const PopupWizard = ({ isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm" className="dark:text-white"  >
      <DialogTitle className="bg-white dark:bg-[#2A3042] ">
        <div className="flex justify-between items-center">
          <div>
            <span>
              <img
                src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
                alt="alternatetext"
                className="h-12"
              ></img>
            </span>
          </div>
          <IconButton onClick={handleClose}>
            <CloseButtonLightMode />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className="bg-white dark:bg-[#2A3042] dark:text-white">
        <div className="font-semibold text-xl text-black dark:text-white">
          Thanks for Installing our White Label SEO App!
        </div>

        <div className="font-semibold text-lg text-black mt-[24px] dark:text-white">
          Instructions
        </div>
        <div className="space-y-4 mt-[16px]">
          <p className="text-sm font-normal text-[#333B4E] mt-[24px] dark:text-[#C0C9E1]">
            <span className="font-bold">Step 1: Watch Welcome Video</span>
            <div className="mt-[24px]">
              <iframe
                src="https://www.loom.com/embed/e1b86892c44345bc9bc8c955266941fd"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="max-w-full h-auto rounded-lg shadow-xl border-2 border-gray-300 bg-white dark:border-[#333B4E] dark:bg-[#333B4E]"
                style={{ width: "100%", height: "315px" }}
                title="Loom Video"
              ></iframe>
            </div>
            
          </p>
          <p className="text-sm font-normal text-[#333B4E] mt-[24px] dark:text-[#C0C9E1]">
            <span className="font-bold">Step 2: Set Up Custom Link</span>
            <div className="mt-[24px]">
              <iframe
                src="https://www.loom.com/embed/944538c3f1894c448b1b4b12c0c23aea"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="max-w-full h-auto rounded-lg shadow-xl border-2 border-gray-300 bg-white dark:border-[#333B4E] dark:bg-[#333B4E]"
                style={{ width: "100%", height: "315px" }}
                title="Loom Video"
              ></iframe>
            </div>
          </p>
          <p className="text-sm font-normal text-[#333B4E] mt-[24px] dark:text-[#C0C9E1]">
            <span>{"https://auth.sceptermarketing.com/dashboard-link/{{location.id}}"}</span>
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PopupWizard;
