import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as VerificatioEmail } from "./../../assets/svgs/verification-email.svg";
import { toast } from "react-toastify";

const VerificationEmailSent = () => {
  const navigate = useNavigate();
  const { error, loggedInUser, loading } = useSelector(
    (state) => state.Agencies
  );

  useEffect(() => {
    if (!loading && loggedInUser && Object.keys(loggedInUser).length > 0) {
      toast.success("New user created successfully!");
      navigate("/"); // Redirect if the user is logged in
    }
    if (error) toast.error(error);
  }, [loggedInUser, error, loading, navigate]);

  return (
    <div className="flex justify-center items-center bg-[#f9f6ff] h-screen px-4 lg:px-0">
      <div className="lg:w-[50%] bg-white pt-10 px-4 md:px-8 lg:px-14 flex flex-col rounded-2xl">
        <div className="flex flex-row justify-center">
          <span>
            <img
              src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
              alt="alternatetext"
              className="h-16 w-44"
            ></img>
          </span>
        </div>
        <div className="font-semibold text-2xl text-[#333B4E] mt-12">
          Verification Email Sent
        </div>
        <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
          We sent a password reset link to your email
        </div>
        <div className="flex justify-center mt-[40px]">
          <VerificatioEmail />
        </div>{" "}
        <div className="font-normal text-sm my-[40px] text-[#5A5F7D] text-center">
          If you didn't receive the email please{" "}
          <Link to="/forgot-password">
            <span className="font-semibold text-sm text-[#556EE6]">
              Click Here
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerificationEmailSent;
