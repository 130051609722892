import React from "react";

const Instructions = () => {
  return (
    <div className="w-full md:w-1/2 flex flex-col px-[30px] lg:px-[60px] pt-[40px] pb-[40px] max-w-[800px] rounded-tr-[50px] rounded-br-[50px] bg-[#EEF1FE] overflow-scroll">
      <div>
        <span>
          <img
            src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
            alt="alternatetext"
            className="h-12"
          ></img>
        </span>
      </div>
      <div className="font-semibold text-2xl mt-[40px]">
        Thanks for Installing our White Label SEO App!
      </div>

      <div className="font-semibold text-lg mt-[24px]">How it Works:</div>
      <div className="mt-[24px]">
        <video
          controls
          className="max-w-full h-auto rounded-lg shadow-xl border-2 border-gray-300 bg-white"
        >
          <source
            src={`/videos/ghl_how_it_works_video.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="mt-[24px]">
        Sign up on the other side of the page to get started
      </div>
    </div>
  );
};

export default Instructions;
