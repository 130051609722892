import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { ReactComponent as ViewIcon } from "./../../assets/svgs/password.svg";
import { ReactComponent as HideIcon } from "./../../assets/svgs/hide-icon.svg";
import { ReactComponent as BackIcon } from "./../../assets/svgs/back-icon.svg";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { resetPassword } from "../../redux/companies/actionCreator";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loggedInUser, loading } = useSelector(
    (state) => state.Agencies
  );

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  useEffect(() => {
    if (
      password &&
      !password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/)
    ) {
      setPasswordError(
        "Password must be at least 8 characters long, include a number, an uppercase, a lowercase letter and a special letter."
      );
    } else {
      setPasswordError("");
    }

    if (confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  }, [password, confirmPassword]);

  useEffect(()=>{
    if (token) {
      try {
        let user = jwtDecode(token); 
        const currentTime = Date.now() / 1000;
  
        if (user.exp <= currentTime) {
          toast.error("Token has expired");          
          navigate("/login")
        }
      } catch (error) {
        toast.error("Invalid token", error);
        navigate("/login")
      }
    } 
    else{
      toast.error("Invalid token");
      navigate("/login")
    }
  },[])

  const validateAndSignup = (event) => {
    event.preventDefault();
    if (passwordError || confirmPasswordError) {
      toast.error("Please resolve the errors.");
      return;
    }
    dispatch(resetPassword(token,password))
    toast.success("Password has been reset successfully!");
    navigate("/login")
};

  return (
    <div className="flex justify-center items-center bg-[#f9f6ff] h-screen px-4 lg:px-0">
      <div className="lg:w-[50%] bg-white pt-10 px-4 md:px-8 lg:px-14 flex flex-col rounded-2xl">
        <div className="flex flex-row justify-center">
          <span>
            <img
              src="https://files.jotform.com/jufs/Hall_Matt/form_files/scepterlogo-long-crop-2x.5d11427e2d8c32.77584617.png?md5=EweODWFOhbTzwK56ScPYjg&expires=1724750748"
              alt="alternatetext"
              className="h-16 w-44"
            ></img>
          </span>
        </div>

        <div className="font-semibold text-2xl text-[#333B4E] mt-12">
          Set New Password
        </div>
        <div className="font-normal text-base mt-[16px] text-[#5A5F7D]">
          Your new password must be different to previously used password.
        </div>
        <form onSubmit={validateAndSignup}>
          <div className="font-normal text-sm mt-8 text-[#5A5F7D]">
            Password
          </div>
          <div className="relative flex items-center mt-1">
            <input
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Enter password"
              className="border-[1px] border-[#E3E6EF] h-9 pl-3 pr-9 pt-2 pb-2 text-sm rounded w-full"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {isPasswordVisible ? (
              <HideIcon
                className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <ViewIcon
                className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          {passwordError && (
            <div className="text-red-500 text-xs mt-1">{passwordError}</div>
          )}

          <div className="font-normal text-sm mt-6 text-[#5A5F7D]">
            Confirm Password
          </div>
          <div className="relative flex items-center mt-1">
            <input
              type={isConfirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm password"
              className="border-[1px] border-[#E3E6EF] h-9 pl-3 pr-9 pt-2 pb-2 text-sm rounded w-full"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {isConfirmPasswordVisible ? (
              <HideIcon
                className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              />
            ) : (
              <ViewIcon
                className="absolute right-3 h-5 w-5 text-gray-400 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              />
            )}
          </div>
          {confirmPasswordError && (
            <div className="text-red-500 text-xs mt-1">
              {confirmPasswordError}
            </div>
          )}
          <div className="mt-8">
            <Button
              backgroundColor="#556EE6"
              textColor="white"
              textContent="Reset Password"
              hoverTextColor="#556EE6"
              widthStatus={true}
            />
          </div>
          <div className="max-w-max mx-auto">
            <Link to="/" className="cursor-pointer">
              <div className="flex flex-row mt-[24px] text-[#556EE6] justify-center items-center mb-[24px] space-x-[4px]">
                <div className="">
                  <span className="">
                    <BackIcon className="h-[12px] w-[14px] font-bold" />
                  </span>
                </div>

                <div className="text-sm font-semibold  text-[#556EE6] dark:text-white cursor-pointer">
                  Back to Login
                </div>
              </div>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
